<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">Request for Quotation</h1>
      <h3 class="container ls-page-header-subtitle">
        For services that do not exactly fit into the services our runners
        offer. Tell us what you need and we will tell you how much it costs.
      </h3>
    </div>
    <div class="ps-section__content ls-page-content">
      <div class="row">
        <div class="col-xl-3 col-lg-2 col-md-2 col-12"></div>
        <div class="col-xl-6 col-lg-8 col-md-8 col-12">
          <form>
            <div class="form-group">
              <label for="quoteTitle">Title</label>
              <input
                class="form-control"
                id="quoteTitle"
                v-model="quote.title"
              />
            </div>

            <div class="form-group">
              <label for="quoteDetails">Quotation Details</label>
              <textarea
                class="form-control"
                id="quoteDetails"
                rows="6"
                v-model="quote.details"
              ></textarea>
            </div>

            <button
              :disabled="isLoading"
              type="submit"
              class="btn btn-primary ls-submit-button"
              @click.prevent="requestQuote()"
            >
              {{ isLoading ? "Processing..." : "Submit" }}
              <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
              <div
                v-if="isLoading"
                class="spinner-border text-light"
                role="status"
              ></div>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "RequestQuotation",
  data() {
    return {
      quote: {
        title: "",
        details: "",
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    requestQuote() {
      this.isLoading = true;
      axios
        .post("/requestquote/quote_requests", this.quote)
        .then((response) => {
          this.isLoading = false;
          console.log(
            "Quote Request Created : " + JSON.stringify(response.data)
          );
          this.$toasted.success(
            "Request Sent, You will get a response in your email inbox."
          );
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toasted.error("Failed to send Request");
        });
    },
  },
};
</script>
